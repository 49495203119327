<template>
  <mo-system>
    <TopBar @navClick="toggleSideDrawer"></TopBar>
    
    <ContentsArea id="contentsArea">
      <div class="content_container">
        <router-view class="router-view"></router-view>
        <BottomBar></BottomBar>
      </div>
    </ContentsArea>
    
    <SideDrawer ref="sideDrawer"></SideDrawer>
  </mo-system>
  
</template>

<script>
import TopBar from './commons/TopBar.vue'
import ContentsArea from './commons/ContentsArea.vue'
import BottomBar from './commons/BottomBar.vue'
import SideDrawer from './commons/SideDrawer.vue'

export default {
  name: 'MobileFrame',
  components: {TopBar, ContentsArea, BottomBar, SideDrawer},
  data() {
    return {
      drawer: false,
    }
  },
  methods: {
    toggleSideDrawer() {
      this.$refs.sideDrawer.toggleSideDrawer();
    }
  }
};
</script>

<style scoped>
.content_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.router-view {
  flex: auto;
}
</style>